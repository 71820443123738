<template>
  <div class="d" :class="[getShowLoaderValue && paymentSuccess == false ? 'backdrop' : '']">
    <Navbar value="5" />
    <div class="root_pay " v-if="$mq === 'largeDevices'">
      <div class="left_pay">
        <!-- {{showItems}} -->
        <div class="mdl" v-if="showItems">
          <ItemsModal :open="showItems" />
        </div>
        <div v-if="paymentSuccess" class="spp">
          <PaymentCongrats />
        </div>
        <div class="spp1" v-else>
          <!-- title -->
          <div class="title_pay">Your Indian Cart Payment</div>
          <div style="display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 2rem;">
            <div class="payment_page_text">Name: <div class="value_text">{{ userDetails.firstName + " " +
              userDetails.lastName }}</div>
            </div>
            <div class="payment_page_text">Contact No: <div class="value_text">{{ userDetails.contactNumber
            }}
              </div>
            </div>
          </div>
          <div class="payment_page_text" style="margin-bottom:2rem;">Email: <div class="value_text">{{ userDetails.email
          }}
            </div>
          </div>
          <div class="delivery_address">
            <div class="h1">Delivery Address:</div>
            <textarea readonly class="d_address">
              {{ getDetails[0].selectedAddress[0].addressLine1 }}, 
              {{ getDetails[0].selectedAddress[0].addressLine2 }},
              {{ getDetails[0].selectedAddress[0].city }},
              {{ getDetails[0].selectedAddress[0].state }},
              {{ getDetails[0].selectedAddress[0].country }},
              {{ getDetails[0].selectedAddress[0].zipCode }}
            </textarea>
          </div>

          <div style="margin-top: 1.5rem; color: #4385f5">
            <b-form-checkbox-group>
              <b-form-checkbox size="lg" plain v-model="isTermsAndConditionsAccepted" @change="acceptTermsAndConditions">
                <div style="font-size: 1rem;">I have read and accepted the <span class="blue" style="cursor: pointer;">
                    terms and conditions.</span></div>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </div>
          <!-- buttons -->
          <div class="bttns">
            <div @click="Back" class="blue backBtn" style="cursor: pointer">
              &#60; Go to Consolidation
            </div>

            <b-button class="bttn2" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              @click="continueToPayment()" :disabled="!isTermsAndConditionsAccepted">
              Pay Now
            </b-button>
            <span v-if="getShowLoaderValue == true" class="loader"></span>
          </div>
        </div>
      </div>
      <!-- right -->
      <div class="right_pay">
        <div class="payment_right_text bold_text">
          <div style="font-size: 1.2rem;">Package Number</div>
          <div style="font-size: 1.2rem;">{{ getDetails[0].packageNo }}</div>
        </div>
        <div class="payment_right_text">
          <div v-if="!paymentSuccess">Total Items:
            <span v-if="yourCartItems.has($route.params.id)" class="bold_text">{{ getDetails[0].selectedItems.length +
              yourCartItems.get($route.params.id).length }}</span>
            <span v-else class="bold_text">{{ getDetails[0].selectedItems.length }}</span>
          </div>
          <div v-else>Total Items: <span class="bold_text">{{ getDetails[0].selectedItems.length }}</span></div>
          <div class="blue_text bold_text" style="cursor: pointer;" @click="onClick">View Existing Items</div>
        </div>
        <div>Weight: <span class="orange_text bold_text">{{ itemsWeight }} KG</span></div>
        <div>Package Dimensions: <span class="bold_text">{{ getDetails[0].packageDimensions }}</span></div>
        <div class="payment_right_text">
          <div>Volumetric Weight: <span class="bold_text">{{ getDetails[0].volumetricWeight }}KG </span></div>
          <div style="cursor: pointer;"><i class="fa fa-circle-info" @click="showVolumetricWeightModal"></i></div>
        </div>

        <hr class="custom_hr">

        <div
          v-if="!paymentSuccess && yourCartItems.has($route.params.id) && yourCartItems.get($route.params.id).length > 0">
          <div class="payment_right_text">
            <div class="bold_text" style="font-size: 1.2rem;">Cart Items</div>
            <div class="bold_text" style="font-size: 1.2rem; cursor: pointer;" @click="Back">+ Add More Items</div>
          </div>

          <div v-for="item in yourCartItems.get($route.params.id)" class="your_cart_items_2">
            <div class="img_container">
              <img :src="item.product[0].productImages[0]" class="your_cart_items_image" />
            </div>
            <div style="width: 60%;">
              <div class="your_cart_items_text bold_text">{{ shortName(item.product[0].name) }}</div>
              <div class="your_cart_items_text">{{ item.variant[0].shippingWeight }}gms</div>
              <div class="your_cart_items_text">Qty: {{ item.quantity }}</div>
            </div>
            <div style="font-weight: bold;" v-if="isItemAvailable(item)">
              <div
              v-if="item.variant && item.variant[0].priceOffer && item.variant[0].priceOffer < item.variant[0].priceOriginal">
              ₹ {{ item.variant ? item.variant[0].priceOffer * item.quantity : "" }}</div>
              <div v-else>
                ₹ {{ (item.variant) ? (item.variant[0].priceOriginal) * item.quantity : "" }}
              </div>
            </div>
            <div v-else style="color: #e43535; font-weight: bold;">Out Of Stock</div>
            <!-- <i class="fa-solid fa-trash icons__" @click="removeItems(item)" style="color: #f54141"></i> -->
          </div>
        </div>

        <div v-else-if="!paymentSuccess && !yourCartItems.has($route.params.id)" class="no_items_card">
          <div class="bold_text" style="font-size: 1.2rem; cursor: pointer;" @click="Back">+ Add More Items</div>
          <img src="../dashboard/MyOrders/NoItemsInCartImg.svg" alt="No items image" />
          <div>“ <span class="no_items_text">Your cart is currently empty. </span>Add the recommended YIC products
            to keep exploring.”</div>
        </div>

        <hr class="custom_hr" v-if="!paymentSuccess">

        <div class="payment_right_text" style="font-size: 1.2rem;">
          <div class="bold_text">Cart Total</div>
          <div class="bold_text" v-if="!paymentSuccess">₹ {{ getCartTotal }}</div>
          <div class="bold_text" v-else>₹ {{ getDetails[0].cartTotal ? getDetails[0].cartTotal : 0 }}</div>
        </div>
        <div class="payment_right_text" style="font-size: 1rem;">
          <div>Shipping Carrier Cost</div>
          <div v-if="!paymentSuccess && selectedShippingPartner">
            ₹{{
              selectedShippingPartner.price }}</div>
          <div v-else>₹{{ getDetails[0].shippingPrice }}</div>
        </div>
        <div class="payment_right_text" style="font-size: 1rem;">
          <div>YIC Consolidation Charges</div>
          <div>₹{{ getYICCharges }}</div>
        </div>
        <div v-if="getOtherCharges" v-for="ad_charges in getAllAdditionalCharges" class="item" style="font-size: 1rem;">
          <div class="item_name">{{ ad_charges.title }}</div>
          <div class="item_val">₹{{ ad_charges.amount }}</div>
        </div>

        <hr class="custom_hr">

        <div class="payment_right_text" style="font-size: 1.2rem;">
          <div class="bold_text">YIC Total</div>
          <div class="bold_text">₹{{ getFinalPrice }}</div>
        </div>

      </div>
    </div>
    <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'" class="payment_">
      <div class="mdl" v-if="showItems">
        <ItemsModal :open="showItems" />
      </div>
      <div v-if="paymentSuccess" class="spp">
        <PaymentCongrats />
      </div>
      <div class="top" v-else>
        <div class="heading_t">Your Indian Cart Payment</div>
        <div style="display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 1rem;">
          <div class="payment_page_text">Name: <div class="value_text">{{ userDetails.firstName + " " +
            userDetails.lastName }}</div>
          </div>
          <div class="payment_page_text">Contact No: <div class="value_text">{{ userDetails.contactNumber
          }}
            </div>
          </div>
        </div>
        <div class="payment_page_text" style="margin-bottom:1rem;">Email: <div class="value_text">{{ userDetails.email
        }}
          </div>
        </div>
        <div class="delivery_address">
          <div class="h1">Delivery Address:</div>
          <textarea readonly class="d_address">
              {{ getDetails[0].selectedAddress[0].addressLine1 }}, 
              {{ getDetails[0].selectedAddress[0].addressLine2 }},
              {{ getDetails[0].selectedAddress[0].city }},
              {{ getDetails[0].selectedAddress[0].state }},
              {{ getDetails[0].selectedAddress[0].country }},
              {{ getDetails[0].selectedAddress[0].zipCode }}
            </textarea>
        </div>
      </div>
      <div class="middle_">
        <div class="right_pay">
          <div class="fr">
            <div class="h3 blue">{{ getDetails[0].packageNo }}</div>
            <div style="cursor: pointer;" class="h4 blue" @click="onClick">View Existing Items</div>
          </div>
          <!-- {{getDetails}} -->
          <div class="sr">
            <div v-if="!paymentSuccess">Total Items:
              <span v-if="yourCartItems.has($route.params.id)" class="bold_text">{{ getDetails[0].selectedItems.length +
                yourCartItems.get($route.params.id).length }}</span>
              <span v-else class="bold_text">{{ getDetails[0].selectedItems.length }}</span>
            </div>
            <div v-else>Total Items: <span class="sr bold_text">{{ getDetails[0].selectedItems.length }}</span></div>
          </div>
          <div class="sr">Weight: <span class="orange_text bold_text">{{ itemsWeight }} KG</span></div>
          <div class="sr">Package Dimensions: <span class="bold_text">{{ getDetails[0].packageDimensions }}</span></div>
          <div class="payment_right_text sr">
            <div>Volumetric Weight: <span class="bold_text">{{ getDetails[0].volumetricWeight }}KG </span></div>
            <div style="cursor: pointer;"><i class="fa fa-circle-info" @click="showVolumetricWeightModal"></i></div>
          </div>

          <hr class="custom_hr">
          <!-- shipping partner -->
          <div
            v-if="!paymentSuccess && yourCartItems.has($route.params.id) && yourCartItems.get($route.params.id).length > 0">
            <div class="payment_right_text">
              <div class="bold_text" style="font-size: 1.1rem;">Cart Items</div>
              <div class="bold_text" style="font-size: 1.1rem; cursor: pointer;" @click="Back">+ Add More Items</div>
            </div>

            <div v-for="item in yourCartItems.get($route.params.id)" class="your_cart_items_2">
              <div class="img_container">
                <img :src="item.product[0].productImages[0]" class="your_cart_items_image" />
              </div>
              <div style="width: 60%;">
                <div class="your_cart_items_text bold_text">{{ shortName(item.product[0].name) }}</div>
                <div class="your_cart_items_text">{{ item.variant[0].shippingWeight }}gms</div>
                <div class="your_cart_items_text">Qty: {{ item.quantity }}</div>
              </div>
              <div style="font-weight: bold;" v-if="isItemAvailable(item)">
                ₹ {{ (item.variant) ? (item.variant[0].priceOffer || item.variant[0].priceOriginal) * item.quantity : ""
                }} <div
                  v-if="item.variant && item.variant[0].priceOffer && item.variant[0].priceOffer < item.variant[0].priceOriginal">
                  ₹ {{ item.variant ? item.variant[0].priceOriginal * item.quantity : "" }}</div>
              </div>
              <div v-else style="color: #e43535; font-weight: bold;">Out Of Stock</div>
              <!-- <i class="fa-solid fa-trash icons__" @click="removeItems(item)" style="color: #f54141"></i> -->
            </div>
          </div>
          <div v-else-if="!paymentSuccess && !yourCartItems.has($route.params.id)" class="no_items_card">
            <div class="bold_text" style="font-size: 1.2rem; cursor: pointer;" @click="Back">+ Add More Items</div>
            <img src="../dashboard/MyOrders/NoItemsInCartImg.svg" alt="No items image" />
            <div>“ <span class="no_items_text">Your cart is currently empty. </span>Add the recommended YIC products
              to keep exploring.”</div>
          </div>
          <hr class="custom_hr" v-if="!paymentSuccess">

          <div class="payment_right_text" style="font-size: 1.1rem;">
            <div class="bold_text">Cart Total</div>
            <div class="bold_text" v-if="!paymentSuccess">₹ {{ getCartTotal }}</div>
            <div class="bold_text" v-else>₹ {{ getDetails[0].cartTotal ? getDetails[0].cartTotal : 0 }}</div>
          </div>
          <div class="payment_right_text" style="font-size: 1.1rem;">
            <div>Shipping Carrier Cost</div>
            <div v-if="!paymentSuccess && selectedShippingPartner">
              ₹{{
                selectedShippingPartner.price }}</div>
            <div v-else>₹{{ getDetails[0].shippingPrice }}</div>
          </div>
          <div class="payment_right_text" style="font-size: 1.1rem;">
            <div>YIC Consolidation Charges</div>
            <div>₹{{ getYICCharges }}</div>
          </div>
          <div v-if="getOtherCharges" v-for="ad_charges in getAllAdditionalCharges" class="item"
            style="font-size: 1.1rem;">
            <div class="item_name">{{ ad_charges.title }}</div>
            <div class="item_val">₹{{ ad_charges.amount }}</div>
          </div>

          <hr class="custom_hr">

          <div class="payment_right_text" style="font-size: 1.1rem;">
            <div class="bold_text">YIC Total</div>
            <div class="bold_text">₹{{ getFinalPrice }}</div>
          </div>
        </div>
      </div>

      <div style="margin-top: 1rem; color: #4385f5">
        <b-form-checkbox-group>
          <b-form-checkbox size="sm" plain v-model="isTermsAndConditionsAccepted" @change="acceptTermsAndConditions">
            <div style="font-size: 1rem; margin-left: 1rem;">I have read and accepted the <span class="blue" style="cursor: pointer;"> terms
                and conditions.</span></div>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>

      <div class="bottom" v-if="!paymentSuccess">
        <div @click="Back" class="blue backBtn" style="cursor: pointer">
          &#60; Go to Consolidation
        </div>

        <b-button class="bttn_2" variant="no-color" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          @click="continueToPayment()" :disabled="!isTermsAndConditionsAccepted">
          Pay Now
        </b-button>
        <span v-if="getShowLoaderValue == true" class="loader"></span>
      </div>
    </div>
    <!-- footer -->
    <VolumetricWeightModal ref="vWeightModal" />
    <FooterYic />
  </div>
</template>

<script>
import ItemsModal from "./ItemsModal.vue";
import Navbar from "../navbar/Navbar.vue";
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import FooterYic from '../homepage/footer.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import PaymentCongrats from "./PaymentCongrats.vue";
import yicLogo from "../../assets/yicimages/navbar/logo.png";
import Vue from 'vue'
import VueMq from 'vue-mq'
import VolumetricWeightModal from '../dashboard/MyOrders/VolumetricWeightModal.vue';
import Swal from 'sweetalert2';

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity
  }
})

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    FooterYic,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    Navbar,
    PaymentCongrats,
    ItemsModal,
    yicLogo,
    VolumetricWeightModal,
    BFormCheckboxGroup
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showItems: false,
      selectedPartner: "",
      paymentDone: false,
      selectedPartner: "",
      orderId: "",
      paymentId: "",
      signature: "",
      yiclogo: yicLogo,
      userName: "",
      cartItems: '',
      itemsWeight: 0,
      totalCartPrice: 0,
      temp: 0,
      isTermsAndConditionsAccepted: false
    };
  },
  computed: mapState({
    getDetails: (state) => {
      return state.payments.Details;
    },
    shippingPartnerName: (state) => {
      return state.shippingPartner.shippingPartner;
    },
    userDetails: (state) => {
      return state.basicprofilemodule;
    },
    getStateValues: (state) => {
      return state.payments;
    },
    paymentSuccess: (state) => {
      return state.payments.paymentDone;
    },
    getShowLoaderValue: (state) => {
      return state.payments.showLoader;
    },
    getYICCharges: state => {
      return state.payments.yicCharges
    },
    getGSTCharges: state => {
      return state.payments.gstCharges
    },
    getOtherCharges: state => {
      return state.payments.additionalCharges
    },
    getFinalPrice: state => {
      return state.payments.finalPriceInRupees
    },
    getTitle: state => {
      return state.payments.title
    },
    getAllAdditionalCharges: state => {
      return state.payments.allAdditionalCharges
    },
    getPaymentGatewayOption: state => {
      return state.payments.paymentGatewayOption
    },
    getCCAvenueResponse: state => {
      return state.payments.ccAvenueResponse
    },
    yourCartItems: (state) => {
      return state.storeShoppingCartItems.yourCartItems;
    },
    totalYourCartItemPrice: (state) => {
      return state.storeShoppingCartItems.totalPrice;
    },
    shippingPartnersDetails: state => {
      return state.storeShoppingCartItems.shippingPartnersDetails;
    },
    getCartTotal: state => {
      return state.storeShoppingCartItems.cartTotal
    },
    selectedShippingPartner: state => {
      return state.shippingPartner.selectedShippingPartner
    }
  }),
  methods: {
    Back() {
      this.$router.push("/dashboard/myOrders/consolidationStatus");
    },
    onClick() {
      this.showItems = !this.showItems;
    },
    ...mapActions({
      getPackages: "payments/getShippedDetails",
      getUserDetails: "basicprofilemodule/getUserDetails",
      updatePrice: "payments/updateFinalPrice",
      createRazorpayPayments: "payments/createRazorpayPaymentsSuccess",
      updateCharges: 'payments/updateCharges',
      createPaymentForCCAvenue: 'payments/createCCAvenuePayment',
      paymentStatus: 'payments/getPaymentStatus',
      setPaymentStatus: 'payments/updatePaymentStatus',
      getItemsInShippingPackage: "storeShoppingCartItems/getItemsInShippingPackage",
      updateItemsInUserBinItems: "payments/updateItemsInUserBinItems",
      calculateFinalShippingPriceOfPackage: "storeShoppingCartItems/calculateFinalShippingPriceOfPackage",
      updateShippingPackage: "payments/updateShippingPackage",
      setshippingPartner: "shippingPartner/setShippingPartner",
    }),
    ...mapMutations({
      showLoader: "payments/SHOW_LOADER",
      setNavbar: "routesStore/SET_NAVBAR",
      setSideBar: "routesStore/SET_SIDEBAR",
      setMyOrdersNavbar: "routesStore/SET_MY_ORDERS_NAVBAR",
      setCartTotalTemp: 'storeShoppingCartItems/SET_CART_TOTAL'
    }),
    async continueToPayment() {
      const price = this.totalYourCartItemPrice.has(this.$route.params.id) ? this.totalYourCartItemPrice.get(this.$route.params.id) : 0
      const res = this.yourCartItems.has(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id).length > 0 && this.yourCartItems.get(this.$route.params.id).filter(item => (item.variant[0].inventoryQuantity - item.quantity) >= 0)
      if (this.yourCartItems.has(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id).length != res.length) {
        Swal.fire({
          icon: 'error',
          title: 'Items out Of Stock!',
          text: 'Some items are out of stock now. Please remove those items and buy them later.',
          confirmButtonText: 'OK'
        }).then((result)=> {
        })
      }
      else {
        await this.showLoader(true);
        await this.updatePrice({ shippingPackageDetails: this.getDetails[0], cartItemsPrice: price, shippingPrice: this.selectedShippingPartner ? this.selectedShippingPartner.price : this.getDetails[0].shippingPrice });
        if (this.getPaymentGatewayOption == 'CCAvenue') {
          this.ccAvenuePaymentHandler()
        }
        else {
          this.paymentHandler();
        }
      }
    },
    setPackageNumber(num, size) {
      var s = num + "";
      while (s.length < size) {
        s = "0" + s;
      }
      return s;
    },
    async ccAvenuePaymentHandler() {
      let orderParams = {
        amount: this.getStateValues.finalPriceInRupees,
        shippingPackageId: this.$route.params.id
      }
      // const res = this.yourCartItems.has(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id).length > 0 && this.yourCartItems.get(this.$route.params.id).filter(item => (item.variant[0].inventoryQuantity - item.quantity) > 0)
      // console.log("response after updating quanitty", res);
      await this.createPaymentForCCAvenue({ orderParams: orderParams })
      this.$router.push({
        name: "ccAvenueCheckout",
        params: { merchantId: this.getCCAvenueResponse.merchantId, accessCode: this.getCCAvenueResponse.accessCode, encReq: this.getCCAvenueResponse.encryptedOrderData },
      });
    },
    showVolumetricWeightModal() {
      this.$refs.vWeightModal.showModal();
    },
    async paymentHandler() {
      const comp = this;
      var options = {
        key: this.getStateValues.keyId, // Enter the Key ID generated from the Dashboard
        amount: this.getStateValues.finalPriceInRupees, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "Your Indian Cart",
        description: "Test Transaction",
        image: "https://assets.yourindiancart.com/logo/YourIndianCart_logo-1-color.jpg",
        order_id: this.getStateValues.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response) {
          comp.orderId = response.razorpay_order_id;
          comp.paymentId = response.razorpay_payment_id;
          comp.signature = response.razorpay_signature;
          comp.createRazorpayPayments({
            orderId: comp.orderId,
            paymentId: comp.paymentId,
            signature: comp.signature,
          });
          comp.showLoader(true);
        },
        prefill: {
          name: this.userDetails.firstName,
          email: this.userDetails.email,
          contact: this.userDetails.contactNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new Razorpay(options);
      this.showLoader(false);
      rzp1.on("payment.failed", function (response) {
        alert(response.error.reason);
      });
      rzp1.open();

    },
    shortName(name) {
      let str = name;
      if (str.length > 20) return str.slice(0, 20) + '...'
      return name
    },
    acceptTermsAndConditions(val) {
      console.log("value while checking checkbox", val);
      this.isTermsAndConditionsAccepted = val.length > 0 ? val[0] : val
    },
    isItemAvailable(item){
      return (item.variant[0].inventoryQuantity - item.quantity)>=0
    }
  },
  async mounted() {
    await this.getPackages({ id: this.$route.params.id });
    await this.getItemsInShippingPackage({ shippingPackageId: this.$route.params.id, getQuantity: false })
    const status = await this.paymentStatus({ id: this.$route.params.id });
    if (status == 'Failed') {
      alert('Payment Failed. Please try again.')
      await this.setPaymentStatus({ id: this.$route.params.id })
    }
    this.selectedPartner = this.$route.params.selectedPartner;
    this.getUserDetails();
    this.setNavbar('dashboardLanding');
    this.setSideBar("");
    this.setMyOrdersNavbar("");
    let weight1 = this.yourCartItems.has(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id).length > 0 && this.yourCartItems.get(this.$route.params.id).map(item => {
      return item.variant[0].shippingWeight * item.quantity
    })
    let pricesOfCartItems = this.yourCartItems.has(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id).length > 0 && this.yourCartItems.get(this.$route.params.id).map(item => {
      return (item.variant[0].priceOffer || item.variant[0].priceOriginal) * item.quantity
    })

    let totalPriceOfCart = pricesOfCartItems.length > 0 ? pricesOfCartItems.reduce((acc, val) => acc + val, 0) : 0
    this.setCartTotalTemp(totalPriceOfCart)
    this.cartItemsWeight = weight1.length > 0 && weight1.reduce((accumulator, curValue) => accumulator + curValue, 0)
    console.log("package weight", this.getDetails);
    let weight = 0;
    if (this.getDetails && this.getDetails.length > 0) {
      weight = this.getDetails[0].volumetricWeight > this.getDetails[0].weight ? this.getDetails[0].volumetricWeight : this.getDetails[0].weight
    }
    if (this.yourCartItems.has(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id).length > 0) {
      weight = parseFloat(weight) + 0.2;
    }
    this.temp = Math.ceil(parseFloat(weight) + this.cartItemsWeight / 1000)
    this.itemsWeight = parseInt(Math.ceil(parseFloat(weight) + this.cartItemsWeight / 1000))
    if (this.yourCartItems.has(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id).length > 0 && this.itemsWeight != parseInt(Math.ceil(this.temp))) {
      await this.calculateFinalShippingPriceOfPackage({ weight: parseFloat(this.itemsWeight + 0.2), volumetricWeight: parseFloat(this.getDetails[0].volumetricWeight), shippingPackageId: this.$route.params.id })
    }
    else {
      await this.calculateFinalShippingPriceOfPackage({ weight: parseFloat(this.itemsWeight), volumetricWeight: parseFloat(this.getDetails[0].volumetricWeight), shippingPackageId: this.$route.params.id })
    }
    // await this.updatePrice({ shippingPackageDetails: this.getDetails[0], cartItemsPrice: totalPriceOfCart, finalWeight: this.itemsWeight, shippingPrice: this.shippingPartnersDetails[0].price, cartTotal: totalPriceOfCart == 0 ? (this.getDetails && this.getDetails.length > 0 ? this.getDetails[0].cartTotal : 0) : totalPriceOfCart, numberOfItems: this.getDetails[0].numberOfItems + this.yourCartItems.length });
    // let temp = this.shippingPartnersDetails.has(this.$route.params.id) && this.shippingPartnersDetails.get(this.$route.params.id).length > 0 ? this.shippingPartnersDetails.get(this.$route.params.id)[0].price : 0
    let tmp = this.selectedShippingPartner ? this.selectedShippingPartner.price : this.getDetails[0].shippingPrice
    await this.updateCharges({ shippingPackageDetails: this.getDetails, finalWeight: this.itemsWeight, shippingPrice: tmp, cartTotal: totalPriceOfCart == 0 ? this.getDetails[0].cartTotal : totalPriceOfCart });
  },
  watch: {
    async paymentSuccess(nVal, oVal) {
      if (nVal != oVal) {
        if (nVal == true) {
          let pricesOfCartItems = this.yourCartItems.has(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id).length > 0 && this.yourCartItems.get(this.$route.params.id).map(item => {
            return (item.variant[0].priceOffer || item.variant[0].priceOriginal) * item.quantity
          })
          let totalPriceOfCart = pricesOfCartItems.length > 0 ? pricesOfCartItems.reduce((acc, val) => acc + val, 0) : 0
          console.log("total price of cart", totalPriceOfCart);

          let price = this.selectedShippingPartner ? this.selectedShippingPartner.price : this.getDetails[0].shippingPrice
          // const price = this.shippingPartnersDetails && this.shippingPartnersDetails.length > 0 ? this.shippingPartnersDetails[0].price : ((this.getDetails && this.getDetails.length > 0) ? this.getDetails[0].shippingPrice : 0)
          await this.updateCharges({ shippingPackageDetails: this.getDetails, finalWeight: this.itemsWeight, shippingPrice: price, cartTotal: totalPriceOfCart == 0 ? this.getDetails[0].cartTotal : totalPriceOfCart });
          await this.updateItemsInUserBinItems({ shippingPackageId: this.$route.params.id, yourCartItems: this.yourCartItems.has(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id).length > 0 ? this.yourCartItems.get(this.$route.params.id) : [] })
          let tCartItems = this.yourCartItems.has(this.$route.params.id) && this.yourCartItems.get(this.$route.params.id).length > 0 ? this.yourCartItems.get(this.$route.params.id).length : 0
          let totalItems = this.getDetails[0].selectedItems.length + tCartItems
          await this.updateShippingPackage({ cartTotal: this.getCartTotal, numberOfItems: totalItems, weight: this.itemsWeight, finalPrice: this.getFinalPrice, id: this.$route.params.id, shippingPrice: price })
          if (this.yourCartItems.get(this.$route.params.id).length > 0 && this.itemsWeight != parseInt(Math.ceil(this.temp))) {
            await this.calculateFinalShippingPriceOfPackage({ weight: parseFloat(this.itemsWeight + 0.2), volumetricWeight: parseFloat(this.getDetails[0].volumetricWeight), shippingPackageId: this.$route.params.id })
          }
          else {
            await this.calculateFinalShippingPriceOfPackage({ weight: parseFloat(this.itemsWeight), volumetricWeight: parseFloat(this.getDetails[0].volumetricWeight), shippingPackageId: this.$route.params.id })
          }
          await this.getPackages({ id: this.$route.params.id });
        }
      }
    }
  }
};
</script>
<style scoped>
.root_pay {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 6rem;
  background: #ffffff;
  margin: 2rem;
}

.left_pay {
  padding: 3rem;
}

.bttn2 {
  background: #4385f5;
  color: #ffffff;
  min-width: 120px;
  margin-left: 2rem;
}

.blue {
  color: #4385f5;
}

.h1 {
  font-weight: bold;
  font-size: 14px;
}

.h2 {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

.p {
  font-weight: 400;
  font-size: 12px;
  color: #555555;
}

.title_pay {
  font-weight: 500;
  font-size: 28px;
  color: #000000;
  margin-bottom: 1rem;
}

.fr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address {
  border: 1px solid #c9c9c9;
  width: 241px;
  height: 144px;
  padding: 1rem;
  padding-bottom: 0;
  margin-top: 2.5rem;
}

.bttns {
  display: flex;
  margin-top: 0.8rem;
  gap: 5rem;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.backBtn {
  font-weight: 500;
  font-size: 16px;
}

.read__only {
  width: 50%;
}

.right_pay {
  width: 100%;
  margin-top: 0;
}

.h3 {
  font-weight: 600;
  font-size: 18px;
}

.h4 {
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
}

.hr-line {
  border-bottom: 1px solid #c9c9c9;
  margin: 1.4rem 0;
}

.sr {
  font-weight: 400;
  font-size: 12px;
  color: #414141;
}

.add_charges {
  display: flex;
  flex-direction: row;
}

.sp {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.6rem;
  height: 42px;
  border: 1px solid #4385f5;
  font-weight: 500;
  font-size: 14px;
  margin: 1.9rem 0;
}

.label_ {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 0.6rem 0;
  width: 100%;
}

.name {
  line-height: 18px;
  color: black;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.special-border-2 {
  border-right: 1px solid #bcbcbc;
}

.label_ div {
  padding: 0 1rem;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.item_val {
  font-weight: 500;
  color: #414141;
}

.item_name1 {
  font-weight: 400;
  /* font-size: 16px; */
  color: #6f6f6f;
}

.item_name {
  font-weight: 400;
  /* font-size: 16px; */
  color: #414141;
}

.tot_val {
  font-weight: 500;
  font-size: 20px;
  color: #414141;
}

.h5 {
  font-weight: 400;
  font-size: 20px;
  color: #414141;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  width: 48px;
  height: 48px;
  border: 5px solid #4385f5;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.shipping_price {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_page_text {
  color: #414141;
  font-size: 1rem;
  font-weight: bold;
}

.value_text {
  font-weight: 200;
}

.delivery_address {
  font-weight: bold;
}

.d_address {
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #D9D9D9;
  resize: none;
}

.payment_right_text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.custom_hr {
  height: 2px;
  background-color: #C9C9C9;
}

.your_cart_items_2 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: white;
  border-radius: 0.5rem;
  border: 1.5px solid #D9D9D9;
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  margin-top: 1rem;
}

.your_cart_items_image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img_container {
  width: 4rem;
  height: 4rem;
}

.bold_text {
  font-weight: bold;
  color: #000000;
}

.orange_text {
  color: #e87613;
}

.blue_text {
  color: #4385f5;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.backdrop {
  position: fixed;
  /* background: rgba(0, 0, 0, 0.5); */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}

.timeLine {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control {
  border-radius: 0px;
  border: 1px solid #E87613;
}

.form-control[readonly] {
  background-color: white;
  font-weight: 500;
}

#username {
  color: #E87613;
}

#email {
  color: #E87613;
}

#contact {
  color: #E87613;
}

.no_items_card {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 0.5rem;
  border: 1px solid #D9D9D9;
}

.no_items_card>* {
  margin: 0.5rem;
}

.no_items_text {
  font-size: 1.2rem;
  font-weight: 500;
  color: #e87613;
}

@media screen and (max-width: 1200px) {
  .sp {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.6rem;
    width: 80vw;
    height: 8vh;
    border: 1px solid #4385f5;
    font-weight: 500;
    font-size: 14px;
    margin: 1.9rem 0;
  }



  .sp {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.6rem;
    width: 65vw;
    height: 42px;
    border: 1px solid #4385f5;
    font-weight: 500;
    font-size: 14px;
    margin: 1.9rem 0;
  }

  .payment_ {
    background: #ffffff;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0rem 0rem 0rem 1rem;
  }

  .top {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 1rem;
  }

  .top>div {
    padding: 0.5rem;
  }

  .address {
    margin-top: 3vh;
    margin-bottom: 3vh;
  }

  .heading_t {
    font-size: 6vw;
    font-weight: 500;
    color: black;
  }

  .text_ {
    font-size: 4vw;
    font-weight: 500;
    color: #E87613;
    padding: 0.5rem;
  }

  .grey {
    color: #414141;
  }

  .right_pay {
    padding: 1rem;
  }

  .middle_ {
    margin: 0;
  }

  .bottom {
    margin: 0rem 2rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
  }

  .bttn_2 {
    background: #4385f5;
    color: #ffffff;
    min-width: auto;
    margin-left: 0rem;
  }

  .backBtn {
    margin-bottom: 3vh
  }

  .your_cart_items_text {
    font-size: 0.9rem;
  }
}
</style>