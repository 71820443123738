<template>
  <div class="root_cong">
    <div v-if="$mq === 'largeDevices'">
    <div class="c_title">
      <div class="pic"></div>
      Congratulations!!
    </div>
    <div class="c_p">
      Your order {{getShippingPackageNumber.packageNo}} has been placed. We will let you know once our
      Operational team has shipped it.
    </div>
    <div class="txt">
      You can track your shipment under My Dashboard >> My Orders >> <span class="blue" @click="goToReadyForShipping"> Ready For Shipping Tab</span>
    </div>
    <div class="btnx">
      <b-button
        class="bttn1"
        variant="no-color"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="viewPackage()"
      >
        Check Shipping Status
      </b-button>
    </div>
    <router-view />
    </div>
    <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
      <div class="payment_success_ms">
      <div class="congrats_title">
        <div class="pic"></div>
        Congratulations!!
      </div>
    <div class="c_p">
      Your order {{getShippingPackageNumber.packageNo}} has been placed. We will let you know once our
      Operational team has shipped it.
    </div>
    <div class="txt">
      You can track your shipment under <span class="blue" @click="goToMyDashboard"> My Dashboard</span> >> <span class="blue" @click="goToMyProductsInWarehouse"> My Orders</span> >> <span class="blue" @click="goToReadyForShipping"> Ready For Shipping Tab</span>
    </div>
    <div class="btnx_1">
      <b-button
        class="bttn_1"
        variant="no-color"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="viewPackage()"
      >
        Check Shipping Status
      </b-button>
    </div>
    <router-view />
    </div>
    </div>
  </div>
</template>
<script>
import Navbar from "../navbar/Navbar.vue";
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BFormTextarea,
} from "bootstrap-vue";
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity
  }
})

export default {
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    Navbar,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  computed: mapState({
    getShippingPackageNumber: state => {
      return state.payments.Details[0]
    }
  }),
  data() {
    return {
      rating: [1, 2, 3, 4, 5],
    };
  },
  mounted() {},
  methods: {
    viewPackage() {
      this.$router.push({ name: "readyForShipping" });
    },
    goToMyDashboard() {
      this.$router.push({ name: 'dashboardLanding' })
    },
    goToMyProductsInWarehouse() {
      this.$router.push({ name: 'productsInWarehouse' })
    },
    goToReadyForShipping() {
      this.$router.push({ name: 'readyForShipping' })
    }
  },
};
</script>
<style scoped>
.root_cong {
  width: 75%;
}
.btnx {
  display: flex;
  justify-content: space-between;
}
.view-package {
  display: flex;
  justify-content: flex-start;
  margin: 0%;
}
.bttn1 {
  margin-top: 2rem;
  background: #4385F5;
  color: #ffffff;
}
.pic {
  width: 40px;
  height: 40px;
  background-image: url("../../assets/yicimages/payment/green_tick.png");
}
.c_title {
  font-weight: 500;
  font-size: 32px;
  color: #000000;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.c_p {
  font-weight: 400;
  font-size: 18px;
  color: #808080;
  margin-top: 2rem;
  margin-bottom: 3rem;
  width: 90%;
}
.c_h2 {
  font-weight: 500;
  font-size: 20px;
  color: #414141;
}
.c_h3 {
  font-weight: 500;
  font-size: 18px;
  color: #414141;
  margin-bottom: 0.6rem;
}
.hr-line {
  border-bottom: 1px solid #c9c9c9;
  margin: 1.4rem 0;
}
.txt{
  font-size: 18px;
  font-weight: 500;
}
.blue {
  color: #4385F5;
  cursor: pointer;
}
.rating {
  display: flex;
  gap: 0.8rem;
  margin: 1rem 0;
}

@media screen and (max-width: 1200px){
  .payment_success_ms {
    padding: 1rem 2rem 1rem 1rem;
    display: flex;
    flex-direction: column;
  }
  .root_cong {
    width: 100%;
  }
  .c_p {
  font-weight: 400;
  font-size: 16px;
  color: #808080;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
 }
 .congrats_title {
  font-weight: 500;
  font-size: 6.5vw;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
 }
 .txt {
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
 }
 .btnx_1 {
  width: 100%;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
 }
 .bttn_1 {
  margin-top: 0rem;
  display: flex;
  justify-content: center;
  background: #4385F5;
  color: white;
  width: 100%;
 }
 .pic {
  margin-right: 1rem;
 }
}
</style>