<template>
  <div class="item-modal">
    <b-modal
      id="modal-center"
      centered
      v-model="modalShow"
      title="List Of Items"
      size="lg"
      hide-footer
    >
      <b-table
        sticky-header="400px"
        :bordered="true"
        :hover="true"
        responsive
        :items="getItems"
        :fields="fields"
        v-if="$mq === 'largeDevices'"
      >

      <template v-for="field in getItems"
                 v-slot:cell(pictures)="field">
        <img crossorigin="anonymous" class="image" v-if="field.item.itemSource != 'store' && field.item.productpictures.length>0" :src="field.item.productpictures[0].url" />
        <img class="image" v-else-if="field.item.productpictures.length>0" :src="field.item.productpictures[0].url" />
        <img class="image" v-else alt="No Image Found"/>
       </template>
      
      </b-table>
      <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
      <div class="card item-cards" v-for="item in getItems">
        <div class="s_card_">
          <div class="picture">
            <div v-if="item.productpictures.length>0">
              <img v-if="item.itemSource == 'store'" class="image_1"  :src="item.productpictures[0].url" />
              <img v-else crossorigin="anonymous" class="image_1"  :src="item.productpictures[0].url" />
              </div>
            <div class="image_1" v-else></div>
          </div>
          <div class="item_name blue">{{item.itemName}}</div>
          <div class="item_qty">{{item.quantity}}</div>
        </div>
      </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal, VBModal, BAlert, BCardText, BTable } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import trolley from "../../assets/yicimages/register/trolley.png";
import aeroplane from "../../assets/yicimages/register/aeroplane.png";
import building from "../../assets/yicimages/register/building.png";
import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity
  }
})

export default {
  props: ["open"],
  components: {
    BTable,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  data() {
    return {
      modalShow: this.open,
      fields: [
        { label: "", key: "pictures" },
        { label: "Name", key: "itemName" },
        { label: "Quantity", key: "quantity" },
      ]
    };
  },
  computed: mapState({
      getItems: state => {
      return state.payments.Details[0].selectedItems;
    },
  }),
  methods: {

  },
  mounted() {
  },
};
</script>
<style scoped>
.image {
  width: 55px;
  height: 53px;
  object-fit: contain;
}

@media screen and (max-width: 1200px){
  .s_card_ {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 7vh;
    padding: 0.5rem;
    margin: 0rem;
  }
  .picture {
    width: 5vw;
    height: 5vh;
    display: flex;
  }
  .image_1 {
    width: 5vw;
    height: 5vh;
  }
  .item-cards {
    margin: 0.6rem;
  }
  #modal-center {
    padding: 0rem;
    margin-right: 1rem;
  }
  .blue {
    color: #4385f5;
  }
  .item_qty {
    font-weight: 500;
  }
}
</style>